import { db } from '@/config/firebase';


async function fetchDispatchesByPlateNo(plateNo) {
    let dispatchesObj = {};

    const dbRef = db.collection('dispatches').where("transportation.plateNo", "==", plateNo);
    const querySnapshots = await dbRef.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let dispatchObj = doc.data();
        dispatchesObj[id] = {
            id: id,
            ...dispatchObj
        }
    });

    return dispatchesObj;
}

function generateQuery(filter) {
    let query = db.collection("dispatches");

    if (filter.dispatchId && filter.dispatchId.length > 0) {
        query = query.where("dispatchId", "==", filter.dispatchId);
    } else {
        if (filter.fromTimestamp && filter.fromTimestamp > 0) {
            query = query.where("dateCreated", ">=", filter.fromTimestamp);
        }

        if (filter.toTimestamp && filter.toTimestamp > 0) {
            query = query.where("dateCreated", "<=", filter.toTimestamp);
        }

        if (filter.sourceCompany && filter.sourceCompany.id && filter.sourceCompany.id.length > 0) {
            query = query.where("source.companyId", "==", filter.sourceCompany.id);
        }

        if (filter.destinationCompany && filter.destinationCompany.id && filter.destinationCompany.id.length > 0) {
            query = query.where("destination.companyId", "==", filter.destinationCompany.id);
        }

        if (filter.plateNo && filter.plateNo.length > 0) {
            query = query.where("transportation.plateNo", "==", filter.plateNo);
        }

        if (filter.status && filter.status.length > 0) {
            query = query.where("status", "==", filter.status);
        }

        if (filter.assetCode && filter.assetCode.length > 0) {
            query = query.where("assets", "array-contains", filter.assetCode);
        }

        if (filter.truckCompany && filter.truckCompany.id && filter.truckCompany.id.length > 0) {
            query = query.where("transportation.companyId", "==", filter.truckCompany.id);
        }
    }

    return query;
}

function getDispatchListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            callback(change.type, change.doc.data());
        });
    });
    return listener;
}

async function getDispatchByDispatchId(dispatchId) {
    let query = db.collection('dispatches')
        .where('dispatchId', '==', dispatchId)
    const querySnapshot = await query.get();

    let dispatchObj = {};
    querySnapshot.forEach(item => {
        dispatchObj = item.data();
        dispatchObj.id = item.id;
    });

    return dispatchObj;
}

export default {
    fetchDispatchesByPlateNo,
    getDispatchListener,
    getDispatchByDispatchId
}