<template>
    <b-modal
		:title="`Dispatch ${imageDetails.dispatchId}`"
        id="receipt-image-view"
		:no-close-on-backdrop="true"
        size="lg"
		:hide-footer="true"
        centered
	>
		<b-row>
			<b-col sm="12">
				<div class="thumbnail text-center">
					<b-img
						v-img-orientation-changer
						:src="imageDetails.imageLink" 
						alt="Responsive image"
						class="img-responsive"
						fluid 
					/>
				</div>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';

export default {
    name: 'ProofReceiptImageView',
	data() {
		return {
			imageDetails: {}
		}
	},
	computed: {
		dateApproved() {
			return DateUtil.getFormattedDateWithTime(this.imageDetails.dateApproved);
		}
	},
	mounted() {
		EventBus.$on('onUpdateSelProofReceiptImageView', imageDetails => {
			this.imageDetails = imageDetails;
		});
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelProofReceiptImageView');
	}
}
</script>

<style scoped>
.thumbnail {
    position: relative;
}
.caption {
    position: absolute;
	bottom: 0px;
    left: -10px;
    width: 100%;
}
.strokeme {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
</style>