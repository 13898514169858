<template>
	<b-modal id="approve-dispatch" ref="approve-dispatch" :title="title" ok-title="Save" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true"
		size="lg">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<!-- For Pending Dispatch (Inactive to Active) -->
		<div class="confirm-message" v-show="selDispatch.status === 'Pending'">
			Are you sure you want to approve
			<b>{{ selDispatch.dispatchId }}</b> from
			<b>{{ source }}</b>
			company?
		</div>

		<!-- For In-Transit or Receiving Dispatch (Active to Inactive) -->
		<div v-show="selDispatch.status !== 'Pending'">
			<b-form @submit.stop.prevent="handleSubmit" novalidate>
				<b-container fluid>
					<b-row>
						<b-col md="8">
							<b-form-group label="Recipient Name:" label-for="name">
								<b-form-input name="Name" type="text" v-model="proofOfReceipt.recipientName" maxlength="25"
									v-validate="'required'"></b-form-input>
								<span v-show="errors.has('Name')" class="help-block">{{
									errors.first('Name')
								}}</span>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col md="8">
							<b-form-group label="Notes:" label-for="notes">
								<b-form-textarea name="Notes" type="text" v-model="proofOfReceipt.notes" maxlength="200"
									:rows="3" placeholder="Notes" v-validate="{
										required: true,
										regex: /^([0-9:;,.'\\\/()\-_a-zA-Z\n ])*$/
									}"></b-form-textarea>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b-form-group label="Receipt Photo:" label-for="photo">
								<ImageUpload :image="proofOfReceipt.imgUrl" :imageFileName="imageFileName"
									:folderName="folderName" @onImageChange="updatePhoto" />
							</b-form-group>
						</b-col>
					</b-row>
					<template v-if="showMap">
						<b-row>
							<b-col>
								<GoogleMapPicker @onPickedLocation="updateLocation" />
							</b-col>
						</b-row>
					</template>
				</b-container>
			</b-form>
		</div>
	</b-modal>
</template>

<script>
// Component
import ImageUpload from '@/views/commons/ImageUpload.vue';

// Util
import { DateUtil } from '@/utils/dateutil';
import { ImageUtil } from '@/utils/imageUtil.js';
import { ValidationUtil } from '@/utils/validationUtil';

// API
import dispatchApi from '@/api/dispatchApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import GoogleMapPicker from '@/views/commons/googleMap/GoogleMapPicker';

export default {
	name: 'approve-dispatch',
	components: {
		ImageUpload,
		Loading,
		GoogleMapPicker,
	},
	data() {
		return {
			selDispatch: {},
			selAssetsObj: {},
			source: '',
			proofOfReceipt: {
				recipientName: '',
				notes: '',
				approvedBy: '',
				dateApproved: '',
				imgUrl: '',
			},
			loggedUser: this.$store.getters.loggedUser,
			// the actual image uploaded
			uploadedImage: null,
			// Check for loader
			isLoading: false,
			location: {
				lat: 0.0,
				lng: 0.0,
			},
			has1Up1Down: false,
			unmatchedAssets: [],
			showMap: false,
		};
	},
	computed: {
		title() {
			return 'Receive Dispatch ' + this.selDispatch.dispatchId;
		},
		folderName() {
			if (
				this.selDispatch.destination &&
				this.selDispatch.destination.companyId
			) {
				return this.selDispatch.destination.companyId + '/proofOfReceipt';
			}
			return '';
		},
		imageFileName() {
			return this.selDispatch.dispatchId;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onApproveSelDispatch', (param) => {
			this.$refs['approve-dispatch'].show();

			this.selDispatch = param.item;

			this.has1Up1Down = param.has1Up1Down;
			this.unmatchedAssets = param.unmatchedAssets;

			this.source = this.selDispatch.source.company;

			if (this.selDispatch.status === 'Pending') {
				this.proofOfReceipt = this.selDispatch.proofOfReceipt;
			} else {
				this.proofOfReceipt = {
					recipientName: '',
					notes: '',
					approvedBy: '',
					dateApproved: '',
				};
				this.uploadedImage = null;
				EventBus.$emit('onResetImageUploadField');
			}

			this.assignCurrentLocation(this.proofOfReceipt);
		});
	},
	methods: {
		async assignCurrentLocation(proofOfReceipt) {
			try {
				const location = await this.$getCurrentLocation();
				proofOfReceipt.latitude = location.lat;
				proofOfReceipt.longitude = location.lng;
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}
		},
		updatePhoto(arr) {
			if (arr) {
				this.uploadedImage = arr[0];
				this.showMap = true;
			}
		},

		handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			if (this.selDispatch.status === 'Pending') {
				this.handleSubmit();
			} else {
				// Prevent modal from closing
				evt.preventDefault();

				if (this.uploadedImage === null) {
					this.$toaster.warning('Receipt photo is required.');
					return;
				}

				this.$validator.validateAll().then((isValid) => {
					if (isValid) {
						this.handleSubmit();
					} else {
						this.$toaster.warning(
							'Please address the field/s with invalid input.'
						);
					}
				});
			}
		},

		handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			this.processDispatchForm();

			if (this.selDispatch.status === 'Pending') {
				this.approveDispatch();
			} else {
				this.approveDispatchWithImageUpload();
			}
		},
		processDispatchForm() {
			// Removes excess whitespace
			this.proofOfReceipt.recipientName = ValidationUtil.removeExcessWhiteSpace(
				this.proofOfReceipt.recipientName
			);
			this.proofOfReceipt.notes = ValidationUtil.removeExcessWhiteSpace(
				this.proofOfReceipt.notes
			);

			// Add the person who approves the receipt
			let userName = this.loggedUser.firstName + ' ' + this.loggedUser.lastName;
			this.proofOfReceipt.approvedBy = userName;
			this.proofOfReceipt.dateApproved = DateUtil.getCurrentTimestamp();

			// Add the person who receives the receipt
			this.selDispatch.dateReceived = DateUtil.getCurrentTimestamp();
			this.selDispatch.receivedBy = this.$store.getters.email;

			// Update the dispatch fields
			this.selDispatch.proofOfReceipt = this.proofOfReceipt;
		},

		async approveDispatch() {
			let dispatchId = this.selDispatch.dispatchId;

			try {
				let { data } = await dispatchApi.receiveDispatch(
					this.selDispatch,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					this.$toaster.success(`Dispatch "${dispatchId}" was approved successfully.`);
					this.$refs['approve-dispatch'].hide();
					EventBus.$emit('onCloseReceiveDispatch', data.dispatch);
				} else {
					this.$toaster.error(`Error receiving dispatch "${dispatchId}". Please try again.`);
				}
			} catch (_error) {
				this.$toaster.error(`Error receiving dispatch "${dispatchId}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		async approveDispatchWithImageUpload() {
			let dispatchId = this.selDispatch.dispatchId;

			try {
				if (!this.uploadedImage) {
					// hide loading indicator
					this.isLoading = false;
					this.$toaster.warning('Proof of receipt not yet uploaded.');
					return;
				}
				let uploadResults = await ImageUtil.uploadOnServer(
					this.uploadedImage,
					this.folderName,
					this.imageFileName
				);

				// applied the receipt upload details
				if (!uploadResults.error) {
					this.selDispatch.proofOfReceipt.imgUrl = uploadResults[1];
				}

				// save dispatch
				if (this.has1Up1Down) {
					this.$refs['approve-dispatch'].hide();
					this.receiveWith1Up1Down(this.selDispatch, this.unmatchedAssets);

				} else {
					// update the status to 'Received'
					// and NO 1 up 1 down by default
					this.selDispatch.status = 'Received';
					this.selDispatch.apply1Up1Down = false;

					let { data } = await dispatchApi.receiveDispatch(
						this.selDispatch,
						this.loggedUser.id,
						DateUtil.getCurrentTimestamp()
					);

					if (data.isSuccess) {
						this.$toaster.success(`Dispatch "${dispatchId}"  was received successfully.`);
						this.$refs['approve-dispatch'].hide();
						EventBus.$emit('onCloseReceiveDispatch', data.dispatch);
					} else {
						this.$toaster.error(`Error receiving dispatch "${dispatchId}". Please try again.`);
					}
				}
			} catch (_error) {
				this.$toaster.error(`Error receiving dispatch "${dispatchId}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		receiveWith1Up1Down(dispatch, unmatchedAssets) {
			// active to active
			EventBus.$emit('onReceiveDispatchWith1Up1Down', [
				dispatch,
				unmatchedAssets,
			]);
		},

		updateLocation(location) {
			this.location = location;
			this.proofOfReceipt.latitude = location.lat;
			this.proofOfReceipt.longitude = location.lng;
		},
	},
	beforeDestroy() {
		EventBus.$off('onApproveSelDispatch');
	},
};
</script>

