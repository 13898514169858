<template>
    <div>
        <b-card title="Assets Summary">
            <b-form-group label="Total Dispatched:" label-cols-sm="3" label-class="pt-0" label-align-sm="right"
                class="mt-1">
                <b class="numFont">{{ totalDispatched ? totalDispatched.toLocaleString() : 0 }}</b>
            </b-form-group>

            <b-form-group v-show="dispatch.status === 'Received'" label="Total Received:" label-cols-sm="3"
                label-class="pt-0" label-align-sm="right" class="mt-1">
                <b class="numFont">{{ totalReceived ? totalReceived.toLocaleString() : 0 }}</b>
            </b-form-group>

            <b-form-group v-show="dispatch.status === 'Received'" label="Total Added Expected:" label-cols-sm="3"
                label-class="pt-0" label-align-sm="right" class="mt-1">
                <b class="numFont">{{ totalAddedExpected ? totalAddedExpected.toLocaleString() : 0 }}</b>
            </b-form-group>

            <b-form-group v-show="dispatch.status === 'Received'" label="Total Lost:" label-cols-sm="3" label-class="pt-0"
                label-align-sm="right" class="mt-1">
                <b class="numFont">{{ totalLost ? totalLost.toLocaleString() : 0 }}</b>
            </b-form-group>

            <b-form-group v-show="damagedAssets.length > 0" label="Total Damaged:" label-cols-sm="3" label-class="pt-0"
                label-align-sm="right" class="mt-1">
                <b class="numFont">{{ totalDamaged ? totalDamaged.toLocaleString() : 0 }}</b>
            </b-form-group>
        </b-card>

        <b-row class="mt-3" no-gutters>
            <b-col sm="10" md="5">
                <b-dropdown text=" Select Actions " variant="dark" slot="append">
                    <b-dropdown-item>
                        <json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
                            Export Assets in Excel
                        </json-excel>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
                            Export Assets to CSV
                        </json-excel>
                    </b-dropdown-item>
                </b-dropdown>
            </b-col>
            <b-col sm="1">
            </b-col>
            <b-col sm="6" md="5" offset-sm="6" offset-md="1" class="text-md-right mb-2">
                <b-input-group align="right" class="pagination" prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage"
            :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>
            <template v-slot:cell(expectedAsset)="row">
                <b-icon icon="upc-scan" class="asset-code-icon" v-show="row.item.expectedAssetInput === 'Scan'" />
                <b-icon icon="keyboard-fill" class="asset-code-icon" v-show="row.item.expectedAssetInput === 'Manual'" />
                <b-icon icon="plus-circle" class="asset-code-icon" v-show="row.item.expectedAssetInput === 'Auto'" />
                &nbsp;<span class="numFont">{{ row.item.expectedAsset }}</span>
            </template>

            <template v-slot:cell(actualAsset)="row">
                <b-icon icon="upc-scan" class="asset-code-icon" v-show="row.item.actualAssetInput === 'Scan'" />
                <b-icon icon="keyboard-fill" class="asset-code-icon" v-show="row.item.actualAssetInput === 'Manual'" />
                <b-icon icon="plus-circle" class="asset-code-icon" v-show="row.item.actualAssetInput === 'Auto'" />
                &nbsp;<span class="numFont">{{ row.item.actualAsset }}</span>
            </template>

            <template v-slot:cell(isReceived)="row">
                <span v-if="row.item.isReceived === 'YES'">
                    <b-badge variant="success">YES</b-badge>
                </span>
                <span v-else>
                    <b-badge variant="danger">NO</b-badge>
                </span>
            </template>
            <template v-slot:cell(isDamaged)="row">
                <span v-if="row.item.isDamaged === 'YES'">
                    <b-badge variant="danger">YES</b-badge>
                </span>
                <span v-else>
                    <b-badge variant="success">NO</b-badge>
                </span>
            </template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// Others
import _ from 'lodash';
import JsonExcel from 'vue-json-excel';

export default {
    name: 'dispatch-details-asset-view',
    props: {
        dispatch: {
            type: Object,
            required: true,
        }
    },
    components: {
        JsonExcel
    },
    data() {
        return {
            fields: [
                {
                    key: 'expectedAsset',
                    label: 'Expected Asset',
                    sortable: true,
                },
                {
                    key: 'actualAsset',
                    label: 'Actual Asset',
                    sortable: true,
                },
                {
                    key: 'isReceived',
                    label: 'is Received?',
                    sortable: false,
                },
                {
                    key: 'isDamaged',
                    label: 'is Damaged?',
                    sortable: false,
                },
            ],

            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
        }
    },
    computed: {
        items() {
            let items = [];

            let expectedAssets = this.dispatch.assets ? this.dispatch.assets : [];
            let actualAssets = this.dispatch.actualAssets ? this.dispatch.actualAssets : [];
            let addedExpectedAssets = this.dispatch.addedExpectedAssets ? this.dispatch.addedExpectedAssets : [];
            let assetCodes = _.union(this.dispatch.assets, this.dispatch.actualAssets);

            _.forEach(assetCodes, assetCode => {
                let item = {};

                if (expectedAssets.includes(assetCode) && !addedExpectedAssets.includes(assetCode)) {
                    item.expectedAsset = assetCode;
                    item.expectedAssetInput = this.getExpectedAssetInput(assetCode);
                } else {
                    item.expectedAsset = "-";
                    item.expectedAssetInput = '-';
                }

                if (actualAssets.includes(assetCode)) {
                    item.actualAsset = assetCode;
                    item.actualAssetInput = this.getActualAssetInput(assetCode);
                } else {
                    item.actualAsset = "-";
                    item.actualAssetInput = '-';
                }

                if (this.dispatch.actualAssets && this.dispatch.actualAssets.includes(assetCode)) {
                    item.isReceived = 'YES';
                } else {
                    item.isReceived = 'NO';
                }
                if (this.damagedAssets.includes(assetCode)) {
                    item.isDamaged = 'YES';
                } else {
                    item.isDamaged = 'NO';
                }

                items.push(item);
            });

            return items;
        },
        expectedAssets() {
            let dispatchLog = this.dispatch.inputAssetLog.dispatch;
            let manual = dispatchLog.manual ? dispatchLog.manual : [];
            let scan = dispatchLog.scan ? dispatchLog.scan : [];
            let auto = dispatchLog.auto ? dispatchLog.auto : [];

            // filter addedExpectedAssets from the list
            let addedExpectedAssets = this.dispatch.addedExpectedAssets;
            if (!_.isEmpty(addedExpectedAssets)) {
                manual = _.difference(manual, addedExpectedAssets);
                scan = _.difference(scan, addedExpectedAssets);
                auto = _.difference(auto, addedExpectedAssets);
            }
            return {
                total: manual.length + scan.length + auto.length,
                manual,
                scan,
                auto,
            };
        },
        actualAssets() {
            let receiptLog = this.dispatch.inputAssetLog.receipt;
            const manual = receiptLog.manual ? receiptLog.manual : [];
            const scan = receiptLog.scan ? receiptLog.scan : [];
            const auto = receiptLog.auto ? receiptLog.auto : [];
            return {
                total: manual.length + scan.length + auto.length,
                manual,
                scan,
                auto,
            };
        },
        damagedAssets() {
            return this.dispatch.damagedAssets ? this.dispatch.damagedAssets : [];
        },
        totalRows() {
            return this.items.length
        },

        totalDispatched() {
            let dispatchedAssets = DispatchUtil.getDispatchedAssets(this.dispatch);
            return _.size(dispatchedAssets);
        },
        totalReceived() {
            let receivedAssets = DispatchUtil.getReceivedAssets(this.dispatch);
            return _.size(receivedAssets);
        },
        totalAddedExpected() {
            let addedExpectedAssets = DispatchUtil.getAddedExpectedAssets(this.dispatch);
            return _.size(addedExpectedAssets);
        },
        totalLost() {
            let lostAssets = DispatchUtil.getLostAssets(this.dispatch);
            return _.size(lostAssets);
        },
        totalDamaged() {
            return this.damagedAssets.length;
        },
        /**
         * Returns the set of data to be included in the export. For now this just
         * returns the data as is.
         *
         * @returns {Array} the set of data to be included in the export.
         */
        exportData() {
            return this.items;
        },

        /**
         * Derives the field information based from the data table configuration.
         *
         * @returns {object} the fields to be included in the export.
         */
        exportFields() {
            return {
                'Expected Asset': 'expectedAsset',
                'Expected Asset Input Type': 'expectedAssetInput',
                'Actual Asset': 'actualAsset',
                'Actual Asset Input Type': 'actualAssetInput',
                'is Received': 'isReceived',
                'is Damaged': 'isDamaged',
            };
        },

        fileName() {
            let currTimeStamp = DateUtil.getCurrentTimestamp();
            return 'Dispatch_'+ this.dispatch.dispatchId + '_Assets_' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
        },
    },
    methods: {
        getExpectedAssetInput(assetCode) {
            if (this.expectedAssets.manual.includes(assetCode)) {
                return "Manual";
            } else if (this.expectedAssets.scan.includes(assetCode)) {
                return "Scan";
            } else if (this.expectedAssets.auto.includes(assetCode)) {
                return "Auto";
            }
            // return "Auto" by default
            return "Auto";
        },
        getActualAssetInput(assetCode) {
            if (this.actualAssets.manual.includes(assetCode)) {
                return "Manual";
            } else if (this.actualAssets.scan.includes(assetCode)) {
                return "Scan";
            } else if (this.actualAssets.auto.includes(assetCode)) {
                return "Auto";
            }
            // return "Auto" by default
            return "Auto";
        }
    }
}
</script>

<style scoped>
.asset-code-icon {
    color: #F18F01;
}
</style>